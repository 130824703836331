<template style="width: 100%;height: 100%;">
  <div style="width: 100%;height: 100%;display: flex;">
    <swiper ref="mySwiper" :options="swiperOptions" style="z-index: 0;width: 100%;height: 100%">
      <swiper-slide v-for='(image,index) in imgs' :key='index'>
        <img class="divImg" :src="image" style="object-fit: cover;width:100%; height:100%;">
      </swiper-slide>
    </swiper>

    <!--    <div style="position: absolute;width: 100%;height: 100%;" class="container-bg"></div>-->
    <!--    <div style="position: absolute;width: 100%;height: 100%;background-color: rgba(0,0,0,0.2)"></div>-->

    <div
        style="position: absolute;top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: 100%;display: flex;align-items:center;justify-content:center;flex-direction: column;">


      <div style="display: flex;flex-direction: row;align-items: start;position: absolute;top: 100Px;left: 170Px;">
        <img src="../assets/ic_app_logo.png" style="width: 80Px;margin-bottom: 20Px;border-radius: 15Px;">
      </div>

      <div style="display: flex;position: absolute;bottom: 100Px;right: 380Px;flex-direction: column">

        <div style="display: flex;flex-direction: row">


          <div style="display: flex;flex-direction: column;align-items: center;margin-left: 30Px">

            <div style="background-color: #FFFFFF;padding: 8Px;border-radius: 5Px;" v-qr="options" v-if="options"/>
          </div>

          <div style="display: flex;flex-direction: column;margin-left: 30px;justify-content: space-around">
            <a :href="androidDownloadUrl" style="margin-bottom: 15Px">
              <img src="../assets/androidBtn.png" style="width: 230Px;">
            </a>

            <a :href="iosDownloadUrl" style="margin-bottom: 15Px">
              <img src="../assets/iosBtn.png" style="width: 230Px;">
            </a>

          </div>
        </div>

      </div>

      <div
          style="position: absolute;bottom:0;width: 100%;height: 80Px;display: flex;flex-direction: column;align-items: center;justify-content: center;font-size: 16Px;background-color: #fffaf5">

        <img src="../assets/footer-tip.png" width="1100dp">

      </div>

      <a :href="tgUrl"
         style="position: absolute;right: 50Px;top:50Px;display: flex;flex-direction: row;align-items: center;">
        <img src="../assets/tg.png" width="60">
      </a>


    </div>
  </div>


</template>

<script>
// import mp4Path from "../assets/background_video.mp4"
// import webmPath from "../../assets/background/hanging/Hanging.webm"
// import jpegPath from "../assets/background_pc.jpg"
export default {
  name: "DownLoadForPC",
  data() {
    return {
      androidDownloadUrl: "",
      iosDownloadUrl: "",
      tgUrl: "",
      imgs: [
        require('../../public/bg1.jpg'),
        require('../../public/bg2.jpg'),
      ],
      options: {
        text: window.location.href,
        render: "canvas",
        width: 160,
        height: 160,
        typeNumber: -1,
        correctLevel: 2,
        background: "#ffffff",
        foreground: "#000000"
      },
      inviteCode: "",
      swiperOptions: {
        loop: true,
        direction: "horizontal",
        autoplay: {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }, // 自动轮播
        speed: 1500,   // 轮播速度
        crossFade: true,
        effect: "slide",
      }
    }
  },
  methods: {

    downloadIOS: function () {
      console.log("downloadIOS");
      window.open(this.iosDownloadUrl);
    },

    downloadAndroid: function () {
      console.log("downloadAndroid");
      window.open(this.androidDownloadUrl);
    },
  },
  created() {

    this.$axios.get('/config.json').then(res => {
      console.log(res);
      this.androidDownloadUrl = res.data.android_url;
      this.iosDownloadUrl = res.data.ios_url;
      this.tgUrl = res.data.telegram_url;
      console.log(this.androidDownloadUrl);

    });
  },
  mounted: function () {

  }
}
</script>

<style>

.login_img .divImg {
  background-position: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.swiper-img {
  background: red;
  width: 100%;
  height: 100%;
}
</style>
