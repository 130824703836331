<template>
  <div class="mobile" style="background-color: rgb(246,198,213)">
    <swiper ref="mySwiper" :options="swiperOptions" style="z-index: 0;width: 100%;height: 100%">
      <swiper-slide v-for='(image,index) in imgs' :key='index'>
        <div
        style="display: flex;flex-direction: column;"
        ><img class="divImg" :src="image" style="object-fit: contain;width:100%; height:100%;"></div>
      </swiper-slide>
    </swiper>


    <!--   header   -->
    <img src="../assets/ic_app_logo.png" width="60px"
         style="position: absolute;top: 20px;left: 20px;border-radius: 10px">

    <div
        style="position: absolute;right: 0;bottom: 0;left: 0;display: flex;flex-direction: column;">

      <!--   foot   -->
      <div
          style="background-color: white;height: 80px;width: 100%; display: flex;flex-direction: row;align-items: center;justify-content: space-between">

        <img src="../assets/title-2-mobile.png" width="110px" style="margin-left: 15px">

        <div style="display: flex;flex-direction: row;flex-grow: 1;justify-content: space-evenly">

          <a v-if="webClip && isIphone" v-clipboard:copy="copyValue"
             v-clipboard:success="goDownloadWebClipPage">
            <img src="../assets/iosBnt1.png" width="105px">
          </a>

          <a v-if="isIphone && iosDownloadUrl" :href="iosDownloadUrl" v-clipboard:copy="copyValue">
            <img src="../assets/iosBnt2.png" width="105px">
          </a>


          <a v-if="!isIphone" :href="androidDownloadUrl" v-clipboard:copy="copyValue"
             v-clipboard:success="goDownloadAndroidResume">
            <img src="../assets/androidBnt1.png" width="105px">
          </a>

          <a v-if="!isIphone" :href="iosDownloadUrl" v-clipboard:copy="copyValue">
            <img src="../assets/androidBnt2.png" width="105px">
          </a>
        </div>

      </div>

    </div>

    <a :href="tgUrl"
       style="border-radius: 4Px; position: absolute;right: 40Px;top:8Px;color:white;font-size: 14Px;display: flex;flex-direction: column;align-items: center;">
      <img src="../assets/tg.png" width="30">
      <div style="margin-top: 5px;color: white;font-weight: bold">商务合作</div>
    </a>

    <DownLoadForWX v-if="isOpenInWx"/>

  </div>

</template>

<script>
import DownloadForWX from "./DownloadForWX";
import DownLoadForWX from "./DownloadForWX";

window.HELP_IMPROVE_VIDEOJS = false;
export default {
  name: "DownLoadForMobile",
  components: {DownLoadForWX},
  comments: {
    DownloadForWX
  },
  data() {
    return {
      isIphone: false,
      isOpenInWx: false,
      iosDownloadUrl: null,
      androidDownloadUrl: "",
      h5Url: null,
      tgUrl: null,
      webClip: null,
      copyValue: "",
      inviteCode: "",
      imgs: [
        require('../../public/bgm1.png'),
        require('../../public/bgm2.png'),
      ],
      titles: ["精选", "黑料", "UP主", "萝莉", "去衣"],
      titleIndex: 0,
      swiperOptions: {
        loop: true,
        direction: "horizontal",
        autoplay: {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }, // 自动轮播
        speed: 1500,   // 轮播速度
        crossFade: true,
        effect: "slide",
      }
    }
  },
  methods: {


    checkIsIphone: function () {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        console.log("ios");
        return true;
      } else {
        console.log("andriod");
        return false;
      }
    },

    showGroupListDialog: function (show) {
      this.showGroupList = show;
    },

    onTitleClick: function (index) {
      this.titleIndex = index;
      console.log(index);
    },

    goDownloadWebClipPage: function () {
      window.location.href = this.webClip;
      this.$router.push({
        name: "DownLoadForWebClip",
        params: {
          h5Url: this.webClip,
          copyValue: this.copyValue
        }
      })
    },

    goDownloadAndroidResume: function () {
      window.location.href = this.androidDownloadUrl;
      this.$router.push({
        name: "DownLoadAndroidResume",
      })
    },

    isWeChat: function () {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },

    dateTime2strNow: function () {
      var data = new Date();
      console.log(data.getMonth());
      var Y = data.getFullYear();
      var M = data.getMonth() + 1;
      var D = data.getDate();
      return Y + (M < 10 ? '0' + M : M) + (D < 10 ? '0' + D : D);
    },
  },

  created() {

    this.isIphone = this.checkIsIphone();

    this.isOpenInWx = this.isWeChat();

    // if (this.$route.query.channel != null) {
    //   this.copyValue = "channel://" + this.$route.query.channel;
    // }
    if (this.$route.query.share != null) {
      this.copyValue = "_c://" + this.$route.query.share;
    } else {
      this.copyValue = window.location.href;
    }

    console.log("this.$route.query.share")
    console.log(this.$route.query.share)
    console.log("this.copyValue")
    console.log(this.copyValue)

    this.$axios.get('/config.json').then(res => {
      console.log(res);
      // var dateTimeStr = this.dateTime2strNow();
      // var androidFileName = md5('main.apk' + dateTimeStr)
      // var webcilpFileName = md5('main.config' + dateTimeStr)
      this.androidDownloadUrl = res.data.android_url;
      this.iosDownloadUrl = res.data.ios_url;
      this.h5Url = res.data.h5_url;
      this.tgUrl = res.data.telegram_url;
      this.webClip = res.data.webclip_url;
    });

  },
  mounted: function () {

  }
}
</script>

<style>

.mobile {
  width: 100%;
  height: 100%;
  display: flex;
  background-position: left top;
  margin: 0 auto;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
}


.download-btn-h5 {
  height: 90px;
  background: linear-gradient(#FF2E8B, #FF277A);
  border-radius: 45px;
  position: absolute;
  bottom: 179px;
  left: 0;
  right: 0;
  margin-left: 145px;
  margin-right: 145px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.download-btn-h5 img {
  width: 39px;
  height: 47px;
}

.download-btn-h5 span {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 500;
  margin-left: 23px;
}

.download-btn {
  height: 90px;
  background: linear-gradient(#FF2E8B, #FF277A);
  border-radius: 45px;
  position: absolute;
  bottom: 69px;
  left: 0;
  right: 0;
  padding-left: 97px;
  margin-left: 145px;
  margin-right: 145px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.download-btn img {
  width: 39px;
  height: 47px;
}

.download-btn span {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 500;
  margin-left: 53px;
  color: #FFFFFF;
}

.title-select {
  background-color: #ff0038;
  color: white;
}

.title-select-no {
  background-color: #e3e3e3;
  color: #333333;
}

.box {
  width: 400px;
  height: 400px;
  overflow: hidden;
}

.box img {
  width: 400px;
  transition: margin-top 10s;
  -webkit-transition: margin-top 10s; /* Safari */
  cursor: pointer;
}

.box img:hover {
  margin-top: -4000px;
}

</style>
